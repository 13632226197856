import React from "react";

export default function Stellar() {
  return (
    <div className="stellar-wrapper">
      <img src={require("../../img/nav-background.png")} alt="" />
      <div className="stellar-title" id="top">
        <p className="p1">WELCOME TO CSO</p>
        <h1>Black Hole of</h1>
        <h1>Citrus Sinensis Osbeck</h1>
        <p className="p2">
          HOLD CSO and get rewarded in CSO on every transaction!
        </p>
      </div>
    </div>
  );
}
