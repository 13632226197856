import "./App.css";
import Navbar from "./components/Navbar";
import Cso from "./components/Cso";
import Stellar from "./components/Stellar";
import Address from "./components/Address";
import Card from "./components/Card";
import About from "./components/About";
import Tokenomics from "./components/Tokenomics";
import Roadmap from "./components/Roadmap";
import Footage from "./components/Footage";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Stellar />
      <Address />
      <Cso />
      <Card />
      <About />
      <Tokenomics />
      <Roadmap />
      <Footage />
    </div>
  );
}

export default App;
