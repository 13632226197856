import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

export default function Address() {
  let addr = "0x8e518202c9575722183da92555fe64940aaa9c50";
  return (
    <div className="address-wrapper">
      <div className="address-son">
        {" "}
        <div className="search-bar">
          <input type="text" disabled={true} placeholder={addr} />
        </div>
      </div>

      <div className="address-son">
        {" "}
        <CopyToClipboard
          className="search-button"
          text={addr}
          onCopy={() => {
            alert("Copied");
          }}
        >
          <div>
            <p>Copy</p>
          </div>
        </CopyToClipboard>
      </div>
    </div>
  );
}
