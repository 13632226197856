import React from "react";
import { useState } from "react";

export default function Roadmap() {
  const [phase, setPhase] = useState(1);
  let content;
  if (phase === 1) {
    content = (
      <>
        <p>Marketing push to spread awareness</p>
        <p>Website launch</p>
        <p>2000 telegram members</p>
        <p>Meme development</p>
        <p>CSO is Born!</p>
      </>
    );
  } else if (phase === 2) {
    content = (
      <>
        <p>Influencer marketing push</p>
        <p>Listing on coin gecko</p>
        <p>Listing on coin market cap</p>
        <p>5,000 telegram members</p>
        <p>3,000 holders</p>
        <p>Third party audit</p>
        <p>Voluntary donation for community + charity partnership</p>
      </>
    );
  } else if (phase === 3) {
    content = (
      <>
        <p>10,000 holders</p>
        <p>10,000 telegram members</p>
        <p>Air drop #2</p>
        <p>Large influencer marketing push</p>
        <p>Listings on major cex</p>
        <p>More memes from community</p>
        <p>Credit Card For crypto live on our website</p>
      </>
    );
  } else if (phase === 4) {
    content = (
      <>
        <p>15,000 holders</p>
        <p>increase marketing</p>
        <p>CSO Swap</p>
        <p>Add way more memes</p>
        <p>Website Redesign</p>
        <p>CSO Paw (Paw wallet growth tracker)</p>
      </>
    );
  } else {
    content = (
      <>
        <p>30,000 Holders</p>
        <p>
          Release NFT market place for CSO Treats . CSO Treats will be available
          to CSO holders
        </p>
        <p>Memes Everywhere</p>
        <p>$250k Donation to charity of community choice at $500m market cap</p>
        <p>
          $500k Donation to plant shelters for Citrus Sinensis Osbeck at $1b
          market cap
        </p>
        <p>Help Citrus Sinensis Osbeck suffer from flood at $25b market cap</p>
      </>
    );
  }
  let shadow;
  if (phase === 1) {
    shadow = "Born";
  } else if (phase === 2) {
    shadow = "Grow";
  } else if (phase === 3) {
    shadow = "Bloom";
  } else if (phase === 4) {
    shadow = "Yield";
  } else {
    shadow = "Harvest";
  }

  return (
    <div className="roadmap-wrapper" id="roadmap">
      <div className="roadmap-title">
        <h1>CSO Roadmap</h1>
        <h2>{shadow}</h2>
      </div>
      <div className="roadmap-top-wrapper">
        <div className="roadmap-top">
          <div className="roadmap-button" onClick={() => setPhase(1)}>
            <p>Birth</p>
          </div>
          <div className="roadmap-button" onClick={() => setPhase(2)}>
            <p>Grow</p>
          </div>
          <div className="roadmap-button" onClick={() => setPhase(3)}>
            <p>Bloom</p>
          </div>
          <div className="roadmap-button" onClick={() => setPhase(4)}>
            <p>Yield</p>
          </div>
          <div className="roadmap-button" onClick={() => setPhase(5)}>
            <p>Harvest</p>
          </div>
        </div>
      </div>
      {/* <div className="roadmap-avatar-wrapper">
        <img src={require("../../img/orange5@4x5.png")} alt="" />
      </div> */}
      <div className="roadmap-bottom">
        <p>{content}</p>
      </div>
    </div>
  );
}
