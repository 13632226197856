import React from "react";

export default function Tokenomics() {
  return (
    <div className="tokenomics-wrapper" id="tokenomics">
      <div className="huge-shadow">
        <p>Tokenomics</p>
      </div>
      <div className="tokenomics-title">
        <h1>
          Our <span>Tokenomics</span>
        </h1>
      </div>
      <div className="tokenomics-sub-wrapper">
        <div className="tokenomics-card left">
          {/* <div className="tokenomics-card-left">
            <div className="tokenomics-avatar">
              ksdfnksdf
              <img src="" alt="" />
            </div>
          </div> */}
          <div className="tokenomics-card-right">
            <div className="tokenomics-card-title">
              <p>7%</p>
            </div>
            <div className="tokenomics-card-content">
              <p>Of Each Transaction Added to Liquity and Destruction</p>
            </div>
          </div>
        </div>
        <div className="tokenomics-avatar">
          <img src={require("../../img/orange1@4x5.png")} alt="" />
        </div>
        <div className="tokenomics-card right">
          {/* <div className="tokenomics-left">
            <div className="tokenomics-avatar">asdasjdj</div>
          </div> */}
          <div className="tokenomics-right">
            <div className="tokenomics-card-title">
              <p>7%</p>
            </div>
            <div className="tokenomics-card-content">
              <p>Of Each Transaction ReDistributed to Holders</p>
            </div>
          </div>
        </div>
      </div>
      <div className="tokenomics-bar">
        <img
          src={require("../../img/tokenomicsBar.png")}
          alt="tokenomics-bar"
        />
      </div>
      <div className="tokenomics-bar-avatar">
        <img src={require("../../img/orange3@4x5.png")} alt="" />
      </div>
    </div>
  );
}
