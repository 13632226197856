import React from "react";

export default function About() {
  return (
    <div className="about-wrapper" id="about">
      <div className="about-card">
        <div className="about-card-left">
          <h1 className="about-title">CSO TOKEN </h1>
          <h3>Citrus Sinensis Osbeck</h3>
        </div>
        <div className="about-card-right">
          <p>
            A total of 210 billion tokens, full community autonomy. 49%
            destroyed 20% create flow pool 20% rock sugar orange angel 7%
            community promotion and operation 4% airdrop and destruction
          </p>
          <p>
            Repurchasing destruction: unique repurchasing destruction mechanism
            after price rise, 5% of the transaction is destroyed
          </p>
          <p>
            Cash holding 24-hour cumulative dividend CSO automatic growth
            mechanism, 5% dividend of trading
          </p>
          <p>
            Ten thousand times deflation: up to 2100W pieces were destroyed,
            with a total deflation of 10000 times
          </p>
        </div>
      </div>
      <div className="about-avatar">
        <img src={require("../../img/orange2@4x5.png")} alt="cso-avatar" />
      </div>
    </div>
  );
}
