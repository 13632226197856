import React from "react";

export default function Card() {
  return (
    <div className="cards-wrapper">
      <div className="card-item">
        <p>
          <span>49%</span> of supply held by the Black Hole
        </p>
      </div>
      <div className="card-item">
        <p>
          Over <span>3,000+ </span>Telegram Members
        </p>
      </div>
      <div className="card-item">
        <p>
          Over <span>10,000+ </span>CSO Token Holders
        </p>
      </div>
      <div className="card-item">
        <p>
          Over <span>4,800+</span> Twitter followers
        </p>
      </div>
    </div>
  );
}
