import React from "react";

export default function Cso() {
  return (
    <div className="cso-wrapper" id="cso">
      <div className="cso-left">
        <div className="cso-title">
          <h4>Citrus Sinensis Osbeck!</h4>
          <h3>Rewards in your wallet</h3>
        </div>
        <div className="cso-intro">
          <p>
            {" "}
            CSO is a deflationary token designed to become more scarce over
            time. All holders of CSO will earn more CSO that is automatically
            sent to your wallet by simply holding CSO coins in your wallet.
            Watch the amount of CSO grow in your wallet as all holders
            automatically receive a 7% fee from every transaction that happens
            on the CSO network. The community receives more CSO coins from the
            fees generated each transaction.
          </p>
        </div>
      </div>
      <div className="cso-right">
        <div className="cso-card-wrapper">
          <div className="cso-card-header">
            {/* <div className="avatar-wrapper">
              <img
                className="avater"
                src="https://cdn.shopify.com/s/files/1/0388/4833/1916/files/burn.png?v=1645717649"
                alt=""
              />
            </div> */}
            <div className="title">
              <h5>Auto & Manual Burns</h5>
            </div>
          </div>
          <div className="details">
            <p>
              We have burned 7%+ of the supply. That means out of the 7% of the
              redistribution 7% will be distributed to the dead wallet. Coins
              are burned with every transaction.
            </p>
          </div>
        </div>
        <div className="cso-card-wrapper">
          <div className="card-header">
            {/* <div className="avatar-wrapper">
              <img
                className="avater"
                src="https://cdn.shopify.com/s/files/1/0388/4833/1916/files/burn.png?v=1645717649"
                alt=""
              />
            </div> */}
            <div className="title">
              <h5>Rewards Holding</h5>
            </div>
          </div>
          <div className="details">
            <p>
              5% of each transaction is redistributed to token holders. That
              means you can earn more CSO Coins by just holding them in your
              wallet.
            </p>
          </div>
        </div>
        <div className="cso-card-wrapper">
          <div className="card-header">
            {/* <div className="avatar-wrapper">
              <img
                className="avater"
                src="https://cdn.shopify.com/s/files/1/0388/4833/1916/files/burn.png?v=1645717649"
                alt=""
              />
            </div> */}
            <div className="title">
              <h5>Locked Liquidty</h5>
            </div>
          </div>
          <div className="details">
            <p>Majority of LP tokens locked & some burned.</p>
          </div>
        </div>
        <div className="cso-card-wrapper">
          <div className="card-header">
            {/* <div className="avatar-wrapper">
              <img
                className="avater"
                src="https://cdn.shopify.com/s/files/1/0388/4833/1916/files/burn.png?v=1645717649"
                alt=""
              />
            </div> */}
            <div className="title">
              <h5>Charity Wallet</h5>
            </div>
          </div>
          <div className="details">
            <p>
              We have a designated charity wallet that receives redistributions
              that are used to support our charity partners.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
