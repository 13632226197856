import React from "react";

export default function Navbar() {
  return (
    <div className="nav-wrapper">
      <div className="nav-bar">
        <a href="#cso">
          <p>CSO</p>
        </a>
        <a href="#about">
          <p>About</p>
        </a>
        <a href="#tokenomics">
          <p>Tokennomics</p>
        </a>
        <a href="#roadmap">
          <p>Roadmap</p>
        </a>
        <a href="#top">
          <p>Buy Now</p>
        </a>
      </div>
    </div>
  );
}
